<template>
  <div class="mdArticle">
      <!-- 头部区域开始 -->
      <header class="header">
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item v-for="(val, index) in breadcrumbData" :key="index" @click.native="reload(index)">
                {{ val.label }}
            </el-breadcrumb-item>
        </el-breadcrumb>
        <div class="header_search">
            <el-select v-model="sortValue" @change="handleSort" clearable>
                <el-option v-for="item in sortOption" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
            <el-select clearable v-model="languageCode" @change="handleLanguage" placeholder="Language">
                <el-option v-for="item in languageOption" :key="item.languageCode" :label="item.languageName" :value="item.languageCode"></el-option>
            </el-select>
            <el-select clearable v-model="versionValue" @change="handleVersion" placeholder="Version">
                <el-option v-for="item in versionOption" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
            <div class="searchInput_box">
                <i class="el-icon-search" @click.stop="handleShowSearch('show')"></i>
                <input type="text" v-model="searchData.keyWord" @keyup.enter="hadleEnterSearch" ref="searchInput" class="searchInput" placeholder=" Enter keywords" />
            </div>
        </div>
      </header>
      <!-- 头部区域结束 -->

      <!-- 内容区域开始 -->
      <main class="main">
            <!-- 结构树区域开始 -->
            <div class="structureTree">
                <el-tree
                    :data="articleTree"
                    :props="defaultProps"
                    :highlight-current="true"
                    @node-click="handleNodeClick"
                    auto-expand-parent
                    :default-expanded-keys="treeExpandedKeys"
                    :expand-on-click-node="false"
                    icon-class="el-icon-arrow-right"
                    node-key="menuId"
                    ref="tree"
                >
                </el-tree>
            </div>
            <!-- 结构树区域结束 -->

            <!-- 文章章节区域开始 -->
            <div class="article_module" v-loading="loadArticle">
                <div class="article_module_item">
                    <div class="item_title">
                        <h3 class="item_title_name">Cookbook</h3>
                        <p class="item_title_line"></p>
                    </div>
                    <div class="iconBox" v-if="!articleList.cookList || !articleList.cookList.length">
                        <svg-icon icon-class="nodate"></svg-icon>
                        <p class="nodata">No Data</p>
                    </div>
                    <div class="item_content" v-for="(val,index) in articleList.cookList" :key="index">
                        <div class="item_content_left">
                            <img src="../../assets/img/book.png" alt="Cookbook">
                        </div>
                        <div class="item_content_right">
                            <div class="content_article_title"><h4 v-html="val.title" @click="goDetailPage(val)"></h4><span title="Recommend" v-if="val.recommendLevel == 1"><svg-icon icon-class="hot"></svg-icon></span></div>
                            <p class="content_article_details" v-html="val.content+' ...'" @click="goDetailPage(val)"></p>
                            <div class="content_article_info">
                                <div class="post_time"><label>Post</label><span>{{moment(val.releaseTime).format("YYYY.MM.DD")}}</span></div>
                                <div class="operation">
                                    <p class="download_num"><svg-icon icon-class="downloads" /> <span>{{val.downloadsNum}}</span></p>
                                    <p class="views"><svg-icon icon-class="hits" /> <span>{{val.viewsNum}}</span></p>
                                    <p class="download" @click="downloadPDF(val)">Download</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="article_module_item" style="margin-top:20px;">
                    <div class="item_title">
                        <h3 class="item_title_name">Troubleshooting Guide</h3>
                        <p class="item_title_line"></p>
                    </div>
                    <div class="iconBox" v-if="!articleList.guideList || !articleList.guideList.length">
                        <svg-icon icon-class="nodate"></svg-icon>
                        <p class="nodata">No Data</p>
                    </div>
                    <div class="item_content" v-for="(val,index) in articleList.guideList" :key="index">
                        <div class="item_content_left">
                            <img src="../../assets/img/book.png" alt="Cookbook">
                        </div>
                        <div class="item_content_right">
                            <div class="content_article_title"><h4 v-html="val.title" @click="goDetailPage(val)"></h4><span title="Recommend" v-if="val.recommendLevel == 1"><svg-icon icon-class="hot"></svg-icon></span></div>
                            <p class="content_article_details" v-html="val.content+' ...'" @click="goDetailPage(val)"></p>
                            <div class="content_article_info">
                                <div class="post_time"><label>Post</label><span>{{moment(val.releaseTime).format("YYYY.MM.DD")}}</span></div>
                                <div class="operation">
                                    <p class="download_num"><svg-icon icon-class="downloads" /> <span>{{val.downloadsNum}}</span></p>
                                    <p class="views"><svg-icon icon-class="hits" /> <span>{{val.viewsNum}}</span></p>
                                    <p class="download" @click="downloadPDF(val)">Download</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            <!-- 文章章节区域结束 -->
      </main>
      <!-- 内容区域结束 -->
  </div>
</template>

<script>
import moment from "moment";
import { searchInfo, getArticleDetail, sendHtml, getMarkdownArticle } from "../../api/common";
export default {
    data(){
        return{
            breadcrumbData:[],
            searchData: {
                keyWord: "",
                menuId: "",
                moduleId: "",
                productVersionId: "",
                pageNumber: 1,
                pageSize: 60,
                sortType: "",
                languageCode:'',
            },
            sortValue:'',
            sortOption:[
                {
                    label: "Post Date",
                    value: "1",
                },
                {
                    label: "Recommend",
                    value: "2",
                },
                {
                    label: "Page View",
                    value: "4",
                },
            ],
            languageCode:'',
            languageOption:[],
            versionValue:'',
            versionOption:[],
            articleTree:[],
            defaultProps: {
                children: "children",
                label: "label",
            },
            treeExpandedKeys:[],
            Pagination: {
                total: 0,
                pageSize: 20,
                pageNumber: 1,
            },
            firstArticle:{},
            articleList:{},
            loadArticle:false,
        }
    },

    methods:{
        //点击面包屑刷新页面
        reload(index){
            if(index==2){
                this.$router.go(0)
            }
        },

        //获取结构树列表
        getSearchDetail(data) {
            this.loadArticle = true;
            let params = data;
            searchInfo(params).then(res=>{
                this.breadcrumbData = res.data.rows.crumbsList;
                this.languageOption = res.data.rows.languageList;
                this.versionOption = res.data.rows.productVersionList;
                // this.firstArticle = res.data.rows.rows[0];
                // if(this.firstArticle!==undefined){
                    // this.getArticleChapter(this.firstArticle.articleId)
                // }else{
                    // this.loadArticle = false;
                // }
            }).catch(()=>{
                // this.loadArticle = false;
            })
        },

        //获取文章
        getArticleChapter(data){
            this.loadArticle = true;
            getMarkdownArticle(data).then(res=>{
                this.loadArticle = false;
                this.articleList = res.data.rows;
                console.log(res.data);
            }).catch(()=>{
                this.loadArticle = false;
                this.firstArticle = undefined;
            })
        },

        //显示隐藏搜索框
        handleShowSearch(val) {
            if (val == "show" && !this.showSearchInput) {
                this.showSearchInput = true;
                this.$refs.searchInput.focus();
                this.$refs.searchInput.style.width = "400px";
            } else {
                this.getSearchDetail(this.searchData);
            }
        },
        handleHiedSearch() {
            document.addEventListener("click", (e) => {
                if (this.$refs.searchInput) {
                    if (!this.$refs.searchInput.contains(e.target)) {
                        this.showSearchInput = false;
                        this.$refs.searchInput.style.width = "20px";
                        e.stopPropagation();
                    }
                }
            });
        },

        //回车搜索
        hadleEnterSearch() {
            this.getArticleChapter(this.searchData);
        },

        //排序搜索
        handleSort(data){
            this.searchData.sortType = data;
            this.getArticleChapter(this.searchData)
        },

        //切换语言搜索
        handleLanguage(data){
            this.searchData.languageCode = data;
            this.getArticleChapter(this.searchData)
        },

        //切换版本搜索
        handleVersion(data){
            this.searchData.productVersionId = data;
            this.getArticleChapter(this.searchData)
        },

        //点击结构树搜索
        handleNodeClick(val) {
            this.searchData.menuId = val.menuId;
            this.getArticleChapter(this.searchData);
        },

        //路由变化时改变值
        doMemuActive(data) {
            let qureyData = data.params.data ? data.params.data : JSON.parse(localStorage.getItem("library_tree"));
            this.searchData.menuId = qureyData ? qureyData.menuId : this.$route.query.module;
            this.articleTree = [];
            this.articleTree.push(qureyData);
            this.filterTree(this.articleTree)
            this.treeExpandedKeys = [];
            this.treeExpandedKeys.push(qureyData.menuId);
            this.versionValue = "";
            this.getSearchDetail( this.searchData);
            this.getArticleChapter(this.searchData)
            this.$nextTick(() => {
                this.$refs.tree.setCurrentKey(qureyData.menuId);
            });
        },

        //过滤树节点
        filterTree(data){
            var arr = ['Cookbook','Troubleshooting Guide']
            for(var i = 0; i<data.length; i++){
                var item = data[i]
                if(arr.indexOf(item.label)==-1){
                    if(item.children&&item.children.length>0){
                        item.children=item.children.filter(val=>{
                            if(arr.indexOf(val.label)!==-1){
                                return false 
                            }else if(val.children&&val.children.length>0){
                                this.filterTree(val.children)
                            }
                            return true
                        })
                    }
                    return data
                }else{
                    data.splice(i--,1)
                }
            }
            return data
        },

            //下载pdf
        downloadPDF(val) {
            var parmas={
                keyword:'',
                articleId:val.articleId
                }
            getArticleDetail(parmas).then((res) => {
                let content = res.data.rows.articleInfo.content;
                let data = {
                    filename: res.data.rows.articleInfo.title,
                    articleId: res.data.rows.articleInfo.articleId,
                    html: `<!DOCTYPE html>
                    <html lang="zh">
                    <head><meta charset="utf-8" /></head>
                    <body>
                    ${content}
                    </body>
                    </html>`,
                };
                sendHtml(data).then((res) => {
                    let blob = new Blob([res.data], { type: "application/pdf" });
                    this.pdfUrl = window.URL.createObjectURL(blob);
                    var a = document.createElement("a");
                    var filename = val.title;
                    a.href = this.pdfUrl;
                    if (filename.endsWith(".pdf")) {
                        a.download = filename;
                    } else {
                        a.download = filename + ".pdf";
                    }
                    a.click();
                });
            });
        },

        //跳转详情页
        goDetailPage(val) {
           let data = {
                articleType: val.articleType,
                articleId: val.articleId,
                keyword: this.searchData.keyWord
            };
            this.$router.push({
                path: "/detailPage",
                query: { articleData: JSON.stringify(data) },
            });
        },
    },
    
    created(){
        let qureyData = this.$route.params.data ? this.$route.params.data : JSON.parse(localStorage.getItem("library_tree"));
        this.searchData.menuId = qureyData ? qureyData.menuId : this.$route.query.module;
        this.articleTree = [];
        this.articleTree.push(qureyData);
        this.filterTree(this.articleTree)
        this.treeExpandedKeys.push(qureyData.menuId);
        this.$nextTick(() => {
            console.log(qureyData.menuId);
            this.$refs.tree.setCurrentKey(qureyData.menuId);
        });
        this.getSearchDetail(this.searchData);
        this.getArticleChapter(this.searchData)
        this.handleHiedSearch();
    },

    watch: {
        //监听路由变化
        $route(toRouter, fromRouter) {
        this.releaseValue=''
        this.languageCode=''
        this.doMemuActive(toRouter);
        },
    },

    computed:{
        moment(){
            return moment
        },
    }
}
</script>

<style lang="scss" scoped>
.mdArticle{
    width: 100%;
    .header{
        width: 100%;
        background: #324062;
        padding: 13px 41px 13px 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        /deep/ .el-breadcrumb__item .el-breadcrumb__inner{
            font-size: 18px;
            color: #fff;
            cursor: pointer;
        }
        .header_search{
            display: flex;
            /deep/.el-input ::placeholder {
                color: #fff !important;
            }
            /deep/ .el-select {
                .el-input__inner{
                    width: 200px;
                    border: 0;
                    background: none;
                    font-size: 18px;
                    color: #fff !important;
                    text-align: center; 
                }
                .el-input {
                    .el-input__suffix {
                        .el-select__caret {
                            color: #fff;
                            font-size: 18px;
                        }
                    }
                }
            }
            .searchInput_box {
                margin-left: 40px;
                transition: width 1s;
                display: flex;

                i {
                    color: #2d2f36;
                    padding: 0 0 0 20px;
                    font-size: 18px;
                    background: #fff;
                    height: 38px;
                    border-radius: 25px 0 0 25px;
                    line-height: 38px;
                    cursor: pointer;
                }
                .searchInput {
                    width: 20px;
                    border: 0;
                    background: #fff;
                    height: 38px;
                    border-radius: 0 25px 25px 0;
                    outline: none;
                    padding-left: 20px;
                    transition: all 0.5s;
                }
                input::placeholder {
                    color: #fff;
                }
            }
        }
    }
    .main{
        width: 100%;
        padding: 10px 0;
        display: flex;
        justify-content: space-between;
        height: calc(100vh - 180px);
        .structureTree {
            width: 15%;
            background: #fff;
            padding: 15px 0;
            height: 100%;
            /deep/.el-tree {
                overflow-y: auto;
                height: 100%;
            }
            .el-tree /deep/ .el-tree-node__expand-icon.expanded {
                display: flex;
                height: 100%;
            }
            .el-tree /deep/ .el-tree-node__content > .el-tree-node__expand-icon {
                display: flex;
                height: 100%;
                font-size: 16px;
                font-weight: 600;
            }
            .el-tree
                /deep/
                .el-tree--highlight-current
                .el-tree-node.is-current
                > .el-tree-node__content {
                background: #0d39a8 !important;
            }
            .el-tree /deep/ .el-tree-node__content {
                font-size: 16px;
                height: auto;
                display: flex;
                align-items: baseline;
                padding: 10px 0;
            }
            .el-tree /deep/ .el-tree-node__label {
                width: 250px;
                white-space: normal;
                display: flex;
                height: 100%;
                font-size: 16px;
            }
        }
        .article_module{
            width:81.5%;
            height: 100%;
            background: #fff;
            margin-right: 30px;
            padding: 20px;
            overflow: auto;
            .article_module_item{
                width: 100%;
                .item_title{
                    width: 100%;
                    .item_title_name{
                        width: 100%;
                        padding:5px 0;
                        font-size: 20px;
                    }
                    .item_title_line{
                        width: 80px;
                        border-bottom: 3px solid #0d39a8;
                    }
                }
                .iconBox {
                    width: fit-content;
                    margin: 0 auto;
                    text-align: center;
                    padding: 60px 0;
                    .svg-icon {
                        font-size: 80px;
                    }
                    .nodata {
                        text-align: center;
                    }
                }
                .item_content{
                    width:100%;
                    border-radius: 8px;
                    box-shadow: 0px 0px 5px #ccc;
                    margin-top: 20px;
                    display: flex;
                    .item_content_left{
                        width: 15%;
                        img{
                            width: 100%;
                            border-radius: 8px 0 0 8px;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                    .item_content_right{
                        width: 85%;
                        padding: 27px;
                        .content_article_title{
                            width: 100%;
                            display: flex;
                            h4{
                                font-size: 18px;
                                color: #0d39a8;
                                cursor: pointer;
                            }
                            span{
                                font-size: 18px;
                                color: red;
                            }
                        }
                        .content_article_details{
                            width: 100%;
                            font-size: 14px;
                            color: #8e7b8f;
                            padding: 12px 0;
                            min-height: 57px;
                            cursor: pointer;
                        }
                        .content_article_info{
                            width: 100%;
                            display: flex;
                            justify-content: space-between;
                            .post_time{
                                font-size: 14px;
                                color: #9e9fb7;
                                display: flex;
                                align-items: center;
                                span{
                                    margin-left: 5px;
                                }
                            }
                            .operation{
                                display: flex;
                                align-items: center;
                                p{
                                    margin-left: 40px;
                                    .svg-icon{
                                        font-size: 14px;
                                        color: #6d88cb;
                                    }
                                    span{
                                        font-size: 14px;
                                        color: #9e9fb7;
                                        margin-left: 5px;
                                    }
                                }
                                .download{
                                    background: #71b32f;
                                    color: #fff;
                                    border-radius: 35px;
                                    padding: 5px 20px;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }
            .main_Pagination{
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 20px;
                position: relative;
                p{
                    color: #7A7B80;
                    position: absolute;
                    left: 50px;
                    top: 25px;
                }
            }
        }
    }
}

</style>